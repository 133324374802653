import axios from "axios";
import store from "../store";

const API_HOST = "https://frosty-bird-972b.3201623169.workers.dev/api/app";

const TIMEOUT = 5000;

export const instance = axios.create({
  baseURL: API_HOST,
  timeout: TIMEOUT,
  withCredentials: true,
});

instance.interceptors.request.use((config) => {
  const token = store.getState().auth.token;
  config.headers.Authorization = "Bearer " + token;
  return config;
});

instance.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  (response) => {
    if (response.status === 200) {
      const { data } = response;
      return Promise.resolve(data);
    }
  },
  (e) => {
    if (e.response.status === 401) {
      // 用户未授权, 清空store里的token, 并跳转至登录页
      store.dispatch({ type: "auth/updateToken", payload: null });
    }
  }
);

export default instance;
