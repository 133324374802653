import styles from "./index.module.scss";
import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { testApi } from "@/api/index";
import { useAuth } from "hooks/auth";
import { useUser } from "hooks/user";

// 登录页面组件
export default function LoginPage() {
  const [formData, setFormData] = useState({
    username: "",
    passowrd: "",
  });

  const onFinish = (values) => {
    setFormData(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // 路由跳转hook
  const navigate = useNavigate();

  // 用户授权hook
  const auth = useAuth();

  // 用户信息hook
  const user = useUser();

  // 相当于vue里的watch
  useEffect(() => {
    if (formData.username && formData.password) {
      testApi().then((userInfo) => {
        user.addUserInfo(userInfo);
        user.addUserRole(userInfo.role);
        auth.addAuth(userInfo.token);
        navigate("/home/project");
      });
    }
  });

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>系统登陆</h2>
      <Form
        layout="vertical"
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        style={{
          width: 400,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="用户名"
          name="username"
          rules={[
            {
              required: true,
              message: "请输入用户名",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="密码"
          name="password"
          rules={[
            {
              required: true,
              message: "请输入密码",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button block type="primary" htmlType="submit">
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
