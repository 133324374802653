import { Steps } from "antd";

export default function ProjectFlow({ projectId }) {
  const items = [
    {
      //   title: "Finished",
      description: <div>1123</div>,
    },
    {
      //   title: "In Progress",
      description: <div>1123</div>,
    },
    {
      //   title: "Waiting",
      description: <div>1123</div>,
    },
  ];

  return <div>{projectId}</div>;
}
