import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateUserInfo, updateUserRole } from "store/user";

// 用户信息钩子
export const useUser = function () {
  const dispatch = useDispatch();

  // 添加用户信息
  const addUserInfo = useCallback((userInfo) => {
    dispatch(updateUserInfo(userInfo));
  });

  // 添加用户角色
  const addUserRole = useCallback((role) => {
    dispatch(updateUserRole(role));
  });

  return {
    addUserInfo,
    addUserRole,
  };
};
