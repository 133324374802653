import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateToken } from "@/store/auth";

// 用户授权钩子
export const useAuth = function () {
  const dispatch = useDispatch();

  // 清除授权
  const clearAuth = useCallback(() => {
    dispatch(updateToken(""));
  });
  // 添加授权Token
  const addAuth = useCallback((auth) => {
    dispatch(updateToken(auth));
  });
  // 是否授权
  const hasAuth = !!useSelector((state) => state.auth.token);

  return {
    clearAuth,
    addAuth,
    hasAuth,
  };
};
