import styles from "./index.module.scss";
import ContentLayout from "@/layout";
import { Outlet } from "react-router-dom";

export default function HomePage() {
  return (
    <div className={styles.container}>
      <ContentLayout>
        {/* 嵌套路由通过slot的方式传递给Layout */}
        <Outlet />
      </ContentLayout>
    </div>
  );
}
