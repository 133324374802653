import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/auth";
import { useAccess } from "hooks/acess";
import HomePage from "@/pages/home";
import LoginPage from "@/pages/login";
import ProjectPage from "@/pages/project";
import RolePage from "@/pages/role";
import UserPage from "@/pages/user";
import Page403 from "@/pages/error/403";

// 路由保护高阶组件
// 根据用户角色来判断是否有路由权限
function ProtectedRoute({ roles, children }) {
  const auth = useAuth();
  const acess = useAccess(roles);
  if (!auth.hasAuth) return <Navigate to="/login" replace />;
  if (!acess.hasAccess) return <Navigate to="/error/403" replace />;
  return children;
}

const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute roles={["user", "admin"]}>
        <HomePage />
      </ProtectedRoute>
    ),
  },
  // 登录页面路由
  {
    path: "/login",
    element: <LoginPage />,
  },
  // 错误页面路由
  {
    path: "/error",
    children: [
      {
        path: "/error/403",
        element: <Page403 />,
      },
    ],
  },
  // 主应用路由
  {
    path: "/home",
    element: (
      <ProtectedRoute roles={["user", "admin"]}>
        <HomePage />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/home/project",
        element: (
          <ProtectedRoute roles={["user"]}>
            <ProjectPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/home/role",
        element: (
          <ProtectedRoute roles={["admin"]}>
            <RolePage />
          </ProtectedRoute>
        ),
      },
      {
        path: "/home/user",
        element: (
          <ProtectedRoute roles={["admin"]}>
            <UserPage />
          </ProtectedRoute>
        ),
      },
    ],
  },
];

export default routes;
