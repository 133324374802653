import request from "../utils/request";

export const userLogin = async () => {
  const data = await request.post("/login");
  return data;
};

export const testApi = async () => {
  const data = await request.post("/test");
  return data;
};
