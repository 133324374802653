import { Form, Input, Row, Col, Button, Select, DatePicker } from "antd";

export default function SearchForm({ onFormSubmit }) {
  // const onFinish = () => {};
  const onFinishFailed = () => {};
  const onYearChange = () => {};

  return (
    <Form
      name="basic"
      onFinish={onFormSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Row gutter={24}>
        <Col span={5}>
          <Form.Item label="项目类型" name="projectType">
            <Select placeholder="请选择项目类型" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item label="项目名称" name="projectName">
            <Input placeholder="请输入项目名称" />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item label="项目年份" name="projectDate">
            <DatePicker
              style={{ width: "100%" }}
              onChange={onYearChange}
              picker="year"
              placeholder="请选择项目年份"
            />
          </Form.Item>
        </Col>
        <Col span={1.5}>
          <Button block type="primary" htmlType="submit">
            查询
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
