import { createSlice } from "@reduxjs/toolkit";

// 用户状态管理
const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: undefined,
    roles: [],
  },
  reducers: {
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    updateUserRole: (state, action) => {
      state.roles = [action.payload];
    },
  },
});

// reducers会自动生成对应的actions
export const { updateUserInfo, updateUserRole } = userSlice.actions;

export default userSlice.reducer;
