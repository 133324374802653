import { Table } from "antd";
import "../../assets/styles/TableList.scss";

export default function TableList({ tableData, viewDetail }) {
  const columns = [
    {
      title: "项目名称",
      dataIndex: "name",
    },
    {
      title: "项目类型",
      dataIndex: "age",
    },
    {
      title: "项目年份",
      dataIndex: "address",
    },
    {
      title: "操作",
      dataIndex: "",
      key: "x",
      render: (text, record) => (
        <a href="#" onClick={() => viewDetail(record)}>
          查看
        </a>
      ),
    },
  ];

  return (
    <div>
      <Table
        style={{ marginTop: "20px" }}
        columns={columns}
        dataSource={tableData}
        scroll={{ y: "56vh" }}
      />
    </div>
  );
}
