import styles from "./index.module.scss";
import { Button, Layout, Menu, theme } from "antd";
import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Header, Sider, Content } = Layout;

// 页面布局组件,内含嵌套路由
export default function ContentLayout({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  // 菜单项
  const menuItems = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: "项目审计",
      to: "/home/project",
    },
    {
      key: "2",
      icon: <VideoCameraOutlined />,
      label: "用户管理",
      to: "/home/user",
    },
    {
      key: "3",
      icon: <UploadOutlined />,
      label: "角色管理",
      to: "/home/role",
    },
  ];

  // 路由跳转hook
  const navigate = useNavigate();

  // 菜单点击事件
  const changeMenu = (e) => {
    navigate(menuItems[Number(e.key) - 1].to);
  };

  return (
    <Layout className={styles.container}>
      <Sider theme="light" trigger={null} collapsible collapsed={collapsed}>
        {/* <div className="demo-logo-vertical">项目审计管理</div> */}
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          onClick={changeMenu}
          items={menuItems}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {/* 嵌套路由 */}
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}
