import styles from "./index.module.scss";
import SearchForm from "@/components/project/SearchForm";
import TableList from "@/components/project/TableList";
import ProjectFlow from "@/components/project/ProjectFlow";
import { Modal } from "antd";
import { useState } from "react";
import { testApi } from "api";

export default function ProjectPage() {
  const [selectId, setSelectId] = useState("");

  // search表单提交方法
  const onFormSubmit = (form) => {
    console.log(form);
    testApi();
  };

  // 列表行操作事件
  const viewDetail = (row) => {
    setSelectId(row.id);
    setOpen(true);
  };

  const [open, setOpen] = useState(false);

  // 项目列表数据
  const data = [
    {
      id: 1,
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
    },
    {
      id: 2,
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
    },
    {
      id: 3,
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
    },
  ];

  return (
    <div className={styles.container}>
      <SearchForm onFormSubmit={onFormSubmit} />
      <TableList tableData={data} viewDetail={viewDetail} />
      <Modal
        title="流程详情"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        footer={null}
      >
        <ProjectFlow projectId={selectId} />
      </Modal>
    </div>
  );
}
