import { createSlice } from "@reduxjs/toolkit";

// 授权状态管理
const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: "",
  },
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

// reducers会自动生成对应的actions
export const { updateToken } = authSlice.actions;

export default authSlice.reducer;
